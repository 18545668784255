.main-page{
  height: 100%;
  overflow: hidden;
  .ant-layout{
    height: 100%;
    background: #ffffff;
    overflow: hidden;
  }
  .logo {
    padding: 20px 10px;
    text-align: center;
    overflow: hidden;
    box-sizing: border-box;

    img {
      display: block;
      width: 100%;
    }
  }

  .side-menu {
    margin-top: 20px !important;
  }

  .site-header-actions {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    background-color: rgba(240, 242, 245, 1);


    .content {
      flex: 1;
      box-sizing: border-box;
      overflow: hidden;
      .routers{
        height: 100%;
      }
    }
  }
}

@primary-color: rgba(24, 144, 255, 1);@link-color: #1890ff;@success-color: rgba(24, 144, 255, 1);@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);