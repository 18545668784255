.login-container{
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000;
}
.login {
  position: absolute;
  width: 500px;
  left: 50%;
  margin-left: -250px;
  top: 20%;
  background: #ffffff;
  border-radius: 5px;

  .login-input{
    position: absolute;
    right: 14px;
    top: 13px;
  }
  .program-title {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #f17116;
  }

  .login-form {
    padding: 20px;
  }
}
#wx_reg{
  text-align: center;
}
.login-btn{    
  display: flex;
  justify-content: center;

}

@primary-color: rgba(24, 144, 255, 1);@link-color: #1890ff;@success-color: rgba(24, 144, 255, 1);@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);