.container {
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ffffff;
  height: 100%;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}
.containers {
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ffffff;
  height: 100%;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}
.content-message {
  background-color: #f7fcff;
  border-width: 1px;
  border-style: solid;
  border-color: #acd7ff;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.layout-container {
  overflow-y: scroll;
  height: 100%;
}
.pagetions {
  justify-content: flex-end;
  display: flex;
  margin-top: 20px;
}
.addGroupGroid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
}
.addGroupGroid .leftSum,
.addGroupGroid .rightSum {
  /* grid-row-gap: 10px; */
  height: 336px;
  overflow-y: auto;
}
.addGroupGroid .leftAllGroup,
.addGroupGroid .rightSelectGroup {
  display: grid;
  grid-template-columns: 35px auto 10%;
  align-items: center;
  margin-bottom: 20px;
}
.switchType::after {
  content: "开启后，同一个微信用户，每次扫码将看到同一个二维码";
  color: #d9001b;
  position: absolute;
  width: 415px;
  top: 2px;
}
.unionLClass::after {
  content: "开启该功能,可在小程序端使用活码.小程序端用户与H5用户将看到同一个二维码,如果没有在小程序端使用需求，请不要开启。";
  color: #d9001b;
  position: absolute;
  width: 800px;
  top: 2px;
  left: 47px;
}
.ant-form-item-label > label::before {
  position: relative;
  content: "*";
  color: #ff4d4f;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-explain.ant-form-item-explain-error {
  text-align: left;
}
.alert:nth-child(5) {
  width: 120px;
}
.avatar-uploader::after {
  content: "图片格式:jpg/jpeg/png,大小不超过1M,图片尺寸40*40";
  font-size: 14px;
  color: #d9001b;
  display: block;
  width: 357px;
}
.templateOne {
  display: flex;
}
.templateOne > img:nth-child(1) {
  width: 375px;
  height: 667px;
}
.notFlex .ant-form-item-control-input-content {
  display: flex;
  justify-content: start;
}
.titleTop {
  position: absolute;
  top: 140px;
  width: calc(100% - 40px);
  background: #ffffff;
  text-align: center;
  /* display: flex; */
  left: 50%;
  font-size: 22px;
  margin-left: -164px;
  color: #000000;
  font-weight: bolder;
}
.bottomTitle {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  top: 388px;
  color: #999;
  margin-left: 20px;
  font-weight: 500;
  background-color: #fff;
  position: absolute;
  text-align: center;
  width: calc(100% - 40px);
}
.titleTemplate {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 32px;
  background: linear-gradient(to right, #8b4c16, #623208);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 34px;
  font-weight: bolder;
}
.TwoTilte {
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  /* left: 50%; */
  /* margin-left: 59px; */
  text-align: center;
  top: 8px;
  width: 100%;
}
.topNumber {
  font-weight: 600;
  color: #333333;
  position: absolute;
  /* left: 50%; */
  top: 196px;
  width: 100%;
  font-size: 16px;
  text-align: center;
}
.TwoTilte > span {
  width: 200px;
  height: 32px;
  background: #3838fd;
  border-radius: 0px 0px 8px 8px;
  font-size: 16px;
  padding: 9px;
}
.photosImg > div {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.photosImg {
  position: absolute;
  top: 232px;
  width: 100%;
  /* display: flex; */
  display: flex;
  height: 34px;
  justify-content: center;
}
.aroundPhoto {
  width: 34px;
  height: 34px;
  background-color: #eeeeee;
  color: #bbbbbb;
  border-radius: 50%;
  /* text-align: center; */
  display: fe;
  display: flex;
  justify-content: center;
}
.vermiddelBorder {
  position: absolute;
  top: 279px;
  width: calc(100% - 84px);
  display: flex;
  margin-left: 42px;
  height: 1px;
  background: #dddddd;
}
.showTime {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 300px;
  width: 100%;
}
.showTime .bottomCode {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #3838fd;
  font-weight: bolder;
  margin-top: 8px;
}
.showTime .time {
  display: flex;
  font-size: 15px;
  width: 163px;
  margin: 0px auto;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
}
.showTime .time span:nth-child(1) {
  margin-left: 6px;
}
.showTime .time span {
  width: 28px;
  height: 28px;
  background: #ff7545;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  padding: 4px;
}
.showTime .showSuccess {
  width: 250px;
  height: 28px;
  background: #f1fef1;
  margin-bottom: 13px;
  border-radius: 14px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #86d175;
  text-align: center;
  margin-top: 16px;
  line-height: 28px;
}
.showTime .CodeLan {
  position: relative;
}
.showTime .CodeLan .Code {
  position: absolute;
  top: 12px;
  left: 10px;
}
.RedCodeContent {
  position: absolute;
  top: 90px;
  width: 100%;
  padding: 0px 37px;
  box-sizing: border-box;
}
.RedCodeContent .header {
  display: flex;
  align-items: center;
}
.RedCodeContent .header .leftAvatar {
  border-radius: 50%;
  border: 1px solid #ff4918;
  width: 64px;
  height: 64px;
}
.RedCodeContent .header .rightName {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}
.RedCodeContent .header .rightName .teacherName {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
}
.RedCodeContent .header .rightName .rightBottomNumber {
  position: relative;
  display: flex;
}
.RedCodeContent .header .rightName .rightBottomNumber span {
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
}
.RedCodeContent .header .rightName .rightBottomNumber span span {
  color: #FF4918;
  font-size: 14px;
  font-weight: bolder;
}
.RedCodeContent .header .rightName .rightBottomNumber::after {
  content: '';
  border-bottom: 5px solid rgba(255, 73, 24, 0.2);
  position: absolute;
  width: 199px;
  top: 14px;
  left: 22px;
}
.RedCodeContent .showSuccess {
  width: 250px;
  height: 28px;
  background: #f1fef1;
  margin-bottom: 13px;
  border-radius: 14px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #86d175;
  text-align: center;
  margin-top: 16px;
  line-height: 28px;
  margin: 0px auto;
  margin-bottom: 12px;
}
.RedCodeContent .codeRed {
  position: relative;
  width: 226px;
  margin: 0px auto;
}
.RedCodeContent .codeRed .Code {
  position: absolute;
  top: 20px;
  left: 26px;
}
.RedCodeContent .showTimes {
  display: flex;
  font-size: 15px;
  width: 163px;
  margin: 0px auto;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  justify-content: center;
  /* text-align: center; */
  align-items: center;
  margin-top: 43px;
  margin-bottom: 23px;
}
.RedCodeContent .showTimes span:nth-child(1) {
  margin-left: 6px;
}
.RedCodeContent .showTimes span {
  width: 28px;
  height: 28px;
  background: #ff7545;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  padding: 4px;
}
.redBottom {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #FF4918;
  text-align: center;
  width: 100%;
  margin-top: 16px;
  font-weight: bolder;
}
.flexEdit .ant-form-item-control-input-content {
  display: block;
}
.avatar-uploader img {
  width: 100% !important;
  height: 100% !important;
}
